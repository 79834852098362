<template>
  <v-expansion-panels elevation="0" style="padding: 30px; border-radius: 20px">
    <v-expansion-panel>
      <v-expansion-panel-header style="padding: 35px;">
        <h3 style="color:#42352E;">
          Búsqueda detallada
        </h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="filters" :lazy-validation="true">
          <v-row>
            <v-col cols="3">
              <InputPeriodo
                @updateDate="updateDate"
                :bgColor="'white'"
                :inicialDates="false"
                :cleanDates="cleanDates"
                style="min-width: 50%;"
              ></InputPeriodo>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="filtros.areaNegocio" 
                label="Areas de negocio"
                :items="areasNegocio"
                item-text="departamento"
                item-value="id"
                outlined
                color="primary"
                :rules="requiredRule"
                background-color="white"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="filtros.ramos"
                label="Ramo"
                :items="ramos"
                item-text="etiqueta"
                item-value="ramo"
                outlined
                :rules="requiredRule"
                multiple
                color="primary"
                background-color="white"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip color="primary" v-if="index === 0">
                    <span>{{ item.etiqueta }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ filtros.ramos.length - 1 }} más)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="filtros.canales"
                label="Canal"
                :items="canales"
                item-text="etiqueta"
                item-value="canal"
                outlined
                :rules="requiredRule"
                multiple
                color="primary"
                background-color="white"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip color="primary" v-if="index === 0">
                    <span>{{ item.etiqueta }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ filtros.canales.length - 1 }} más)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" style="text-align: end;">
              <v-btn
                color="primary"
                style="border-radius: 15px;  margin-right: 10px;"
                outlined
                dark
                :loading="loading"
                @click="clearFilters"
              >
                <span>Limpiar filtros</span>
              </v-btn>
              <v-btn
                class="common-botton"
                dark
                :loading="loading"
                @click="updatefiltros"
              >
                <span>Filtrar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { getCanalList } from "@/services/canal/canal.service.js";
import { getRamoList } from "@/services/ramo/ramo.service.js";
import { getAreaNegocio } from "@/services/log/log.service.js";

export default {
  components: {
    InputPeriodo: () =>
      import("@/components/commonComponents/forms/InputPeriodo.vue"),
  },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      filtros: {
        ramos: [],
        canales: [],
        areaNegocio: null,
      },
      ramos: [],
      canales: [],
      areasNegocio: [],
      cleanDates: false,
      requiredRule: [(v) => v!=null || "El campo es requerido"],
    };
  },

  computed: {},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    page(v) {
      if (v) this.pagina = v;
    },
    size(v) {
      if (v > 0) this.size = v;
      else this.size = this.total;
    },
  },
  methods: {
    async getRamos() {
      this.ramos = await getRamoList();
    },
    async getCanales() {
      this.canales = await getCanalList();
    },
    async getAreaNegocio() {
      this.areasNegocio = await getAreaNegocio();
    },
    updateDate(date) {
      this.filtros.fechaInicio = date[0];
      this.filtros.fechaFin = date[1];
    },
    updatefiltros() {
      if (this.$refs.filters.validate()) {
        this.$emit("filtrosModificados", this.filtros);
      }
      this.$refs.filters.reset()
      
    },
    clearFilters() {
      this.cleanDates = true;
      Object.assign(this.filtros, {
        ramos: [],
        canales: [],
        areaNegocio: null,
        fechaInicio: "",
        fechaFin: "",
      });
    },
  },
  mounted() {
    this.getRamos();
    this.getCanales();
    this.getAreaNegocio();
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}
</style>
