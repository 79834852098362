import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

export const getLlamadasInboundReport = async (body) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.post(
      "/v1/reportes/llamadas",
      body,
      config
    );
    
    return serverResponse.data
};  


export const getLlamadasInboundReportCsv = async (body) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.post(
      "/v1/reportes/llamadas/file",
      body,
      config
    );
    
    return serverResponse.data
};  