<template>
  <common-card>
    <v-row>
      <v-col>
        <v-row style="padding: 15px 20px 0px 20px;">
          <v-col cols="12" md="6">
            <h1>Llamadas inbound</h1>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <FiltrosLlamadas @filtrosModificados="updateFilters" :loading="loading" />
    <div style="padding: 0px 40px 0px 40px;">
      <hr />
    </div>
    <v-row v-if="showDownloadButton">
      <v-col cols="12" style="text-align: end; padding: 40px 40px 0px 40px;">
        <v-btn
          class="common-botton"
          dark
          :loading="loading"
          @click="downloadFile"
        >
          <span>Descargar reporte</span>
        </v-btn>
      </v-col>
    </v-row>
    <TablaLlamadas
      :llamadas="resultados"
      :loading="loading"
      @paginacionModificada="paginacionModificada"
    />
  </common-card>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import FiltrosLlamadas from "./FiltrosLlamadas.vue";
import TablaLlamadas from "./TablaLlamadas.vue";
import {
  getLlamadasInboundReport,
  getLlamadasInboundReportCsv,
} from "@/services/llamadas/llamadas.service.js";
import download from "downloadjs";

export default {
  components: {
    CommonCard,
    FiltrosLlamadas,
    TablaLlamadas,
  },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      currentFiltros: {},
      currentPage: {
        pagina: 1,
        elementosPagina: 10,
      },
      resultados: {
        leads: [],
        count: 0,
      },
      loading: false,
    };
  },

  computed: {
    showDownloadButton() {
      return !this.resultados.leads.length < 1;
    },
  },
  watch: {
    currentFiltros() {
      this.realizarBusqueda();
    },
  },
  methods: {
    updateFilters(e) {
      this.currentFiltros = e;
    },
    async realizarBusqueda() {
      this.loading = true;
      try {
        const resp = await getLlamadasInboundReport({
          ...this.currentFiltros,
          ...this.currentPage,
        });
        this.resultados = resp.data;
        this.loading = false;
        if (this.resultados.leads.length < 1) {
          this.$toast.info("No se encontraron resultados para los filtros");
        }
      } catch {
        this.$toast.error("No se pudo obtener resultados");
        this.loading = true;
      }
    },
    paginacionModificada(e) {
      this.currentPage.pagina = e.pagina;
      this.currentPage.elementosPagina = e.elementosPagina;
      this.realizarBusqueda();
    },
    async downloadFile() {
      const resp = await getLlamadasInboundReportCsv(this.currentFiltros);
      if (resp) {
        download(resp, `Reporte_Llamadas_cliente.csv`, "application/csv");
      }
    },
  },
  mounted() {},
};
</script>
