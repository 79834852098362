<template>
  <v-row>
    <v-col cols="12" class="mb-10" style="padding: 40px">
      <v-data-table
        :headers="capaingsTable"
        :items="llamadas.leads"
        item-key="name"
        class="elevation-0"
        :loading="loading"
        loading-text="Cargando..."
        no-data-text="Para ver elementos, primero realiza una búsqueda"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
        :server-items-length="llamadas.count"
        @update:page="pagina = $event"
        @update:items-per-page="elementosPagina = $event"
      >
        <template v-slot:top>
          <v-row style="padding: 15px;">
            <v-col cols="8">
              <h2>Resultados de la búsqueda</h2>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.cliente="{ item }">
          {{ getCliente(item)}}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      agentes: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      search: "",
      capaingsTable: [
        { text: "Cliente", value: "cliente" },
        { text: "Ejecutivo atendio", value: "ejecutivo_atendio" },
        { text: "Area de negocio", value: "area_negocio"},
        { text: "Servicio Embebido", value: "servicio" },
        { text: "Tipificación", value: "tipificación" },
        { text: "Fecha llamada", value: "fecha_llamada" },
        { text: "Duracion llamada", value: "duracion_llamada" },
      ],
      elementosPagina: 10,
      pagina: 1,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    llamadas: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    pagina(v) {
      if (v)
        this.$emit("paginacionModificada", {
          pagina: v,
          elementosPagina:
            this.elementosPagina > 0
              ? this.elementosPagina
              : this.llamadas.count,
        });
    },

    elementosPagina(v) {
      if (v)
        this.$emit("paginacionModificada", {
          pagina: this.pagina,
          elementosPagina: v > 0 ? v : this.llamadas.count,
        });
    },
  },
  methods: {
    getCliente(item){
      console.log({item})
      if(String((item.cliente)).trim() != "  ")
        return item.cliente
      else return 'No disponible'
    }
  },
  mounted() {},
};
</script>
<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}
</style>
